import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import Constants from "../../utils/Constants";
import Select from "react-select";
import AWS from "aws-sdk";

export default function Challenges() {
  const [allChallenges, setAllChallenges] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [videos, setVideos] = useState();
  const [selectedTags, setSelectedTags] = useState([]);

  const [newChallenge, setNewChallenge] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [videoUploadingA, setVideoUploadingA] = useState(false);
  const [videoUploadingB, setVideoUploadingB] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoA, setVideoA] = useState(null);
  const [fileA, setFileA] = useState(null);
  const [videoB, setVideoB] = useState(null);
  const [fileB, setFileB] = useState(null);
  const [image, setImage] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [progress, setProgress] = useState(0);
  const [videoProgressA, setVideoProgressA] = useState(0);
  const [videoProgressB, setVideoProgressB] = useState(0);
  const [videoTypeA, setVideoTypeA] = useState(null);
  const [videoTypeB, setVideoTypeB] = useState(null);
  const [isFullDesc, setFullDesc] = useState(null);

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(challenges.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentChallenges = challenges.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  let token = localStorage.getItem("token");
  var f = null;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    fetchChallenges();
    fetchVideos();
  }, []);

  useEffect(() => {
    if (loading) {
      openModal();
    } else {
      closeModal();
    }
  }, [loading]);

  useEffect(() => {
    if (uploading) {
      processUpload();
    }
  }, [uploading]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fetchChallenges = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/challenges`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setChallenges(res.data.data.sort(Constants.sortList));
          setAllChallenges(res.data.data.sort(Constants.sortList));
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const fetchVideos = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/videos`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let data = [];
          res.data.data.forEach((item, index) => {
            let obj = {
              value: item._id,
              label: item.name,
            };
            data.push(obj);
          });
          setVideos(data);
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const addVideo = async (name, url, uploader) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/videos/`,
        {
          name: name,
          url: url,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let result = {
            value: res.data.data._id,
            label: name,
          };
          uploader === "A" ? setVideoA(result) : setVideoB(result);
          fetchVideos();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
      });
  };

  const addChallenge = async (url) => {
    setLoading(true);
    let tagsToSend = [];

    selectedTags.forEach((item, index) => {
      tagsToSend.push(item.value);
    });

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/challenges`,
        {
          title: title,
          thumbnail: url,
          description: description,
          videoA: videoA.value,
          videoB: videoB ? videoB.value : null,
          tags: tagsToSend,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setTitle(null);
          setDescription(null);
          setImage(null);
          setVideoA(null);
          setVideoB(null);
          setSelectedTags([]);
          fetchChallenges();
          setLoading(false);
        } else {
          setTitle(null);
          setDescription(null);
          setImage(null);
          setVideoA(null);
          setVideoB(null);
          setSelectedTags([]);
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const editChallenge = async (url = "") => {
    setLoading(true);
    let tagsToSend = [];

    selectedTags.forEach((item, index) => {
      tagsToSend.push(item.value);
    });

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/challenges/${currentChallenge._id}`,
        {
          title: title,
          thumbnail: url ? url : currentChallenge.thumbnail,
          description: description,
          videoA: videoA.value,
          videoB: videoB ? videoB.value : null,
          tags: tagsToSend,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setTitle(null);
          setDescription(null);
          setImage(null);
          setVideoA(null);
          setVideoB(null);
          setSelectedTags([]);
          fetchChallenges();
          setCurrentChallenge(null);
          setNewChallenge(false);
          setEdit(false);
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const deleteChallenge = async (id) => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/challenges/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          fetchChallenges();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const processUpload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `challenge-images/${image.name}`,
      Body: image,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setUploading(false);
        setProgress(0);
        setImage(null);
        setTitle(null);
        setVideoA(null);
        setVideoB(null);
        setSelectedTags([]);
        toast.error("Error:::" + err);
      } else {
        setUploading(false);
        setProgress(0);
        toast.success("Image uploaded");
        if (isEdit) {
          editChallenge(data.Location);
        } else {
          addChallenge(data.Location);
        }
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      setProgress(uploadedPercentage);
      console.log("Upload progress:", uploadedPercentage);
    });
  };

  const uploadVideo = (file, uploader) => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `videos/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        toast.error(err);
        uploader === "A"
          ? setVideoUploadingA(false)
          : setVideoUploadingB(false);
      } else {
        if (uploader === "A") {
          setVideoUploadingA(false);
          setVideoProgressA(0);
          setFileA(null);
        } else {
          setVideoUploadingB(false);
          setVideoProgressB(0);
          setFileB(null);
        }
        addVideo(
          data.Location.substring(data.Location.lastIndexOf("/") + 1),
          data.Location,
          uploader
        );
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      if (uploader === "A") {
        setVideoProgressA(uploadedPercentage);
      } else {
        setVideoProgressB(uploadedPercentage);
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="p-3">
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <h5 style={{ fontWeight: "600", flex: 1 }}>Challenges</h5>

          <span
            onClick={() => {
              fetchChallenges();
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            refresh
          </span>

          <span
            onClick={() => {
              setNewChallenge(true);
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            add
          </span>

          <input
            style={{ width: "30%" }}
            type="text"
            className="search form-control ml-2"
            placeholder="Search by title..."
            onChange={(e) => {
              if (e.target.value.trim().length > 0) {
                setChallenges(
                  challenges.filter((v) =>
                    v.title
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                  )
                );
              } else {
                setChallenges(allChallenges);
              }
            }}
          />
        </div>

        <div
          className="mt-4"
          style={{
            ...(challenges.length === 0 && {
              width: "100%",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }),
          }}
        >
          {/* Pagination */}
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                className="page-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </span>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                onClick={() => handlePageChange(index + 1)}
                key={index}
                style={{
                  width: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${
                    currentPage === index + 1 ? "#007BFF" : "#E9ECEF"
                  }`,
                  backgroundColor:
                    currentPage === index + 1 ? "#007BFF" : "white",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: currentPage === index + 1 ? "white" : "#007BFF",
                  }}
                >
                  {index + 1}
                </span>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ cursor: "pointer" }}
              >
                Next
              </span>
            </li>
          </ul>
          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-bordered table-hover table-responsive-xl table-responsive-lg table-responsive-mdtable-responsive-sm"
          >
            <colgroup>
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "15%" }} />
              <col span="1" style={{ width: "25%" }} />
              <col span="1" style={{ width: "8%" }} />
              <col span="1" style={{ width: "8%" }} />
              <col span="1" style={{ width: "16%" }} />
              <col span="1" style={{ width: "8%" }} />
            </colgroup>

            <thead className="thead-dark">
              <tr>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>#</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Image</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Title</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Description
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Video A</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Video B</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Tags</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Actions</th>
              </tr>
            </thead>
            {currentChallenges.length > 0 ? (
              <tbody>
                {currentChallenges.map((item, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >{`${
                        itemsPerPage * (currentPage - 1) + (index + 1)
                      }`}</td>
                      <td className="align-middle text-center">
                        <img
                          src={item.thumbnail}
                          style={{
                            height: "90px",
                            width: "150px",
                            borderRadius: "5px",
                          }}
                        />
                      </td>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "800",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item.title}
                      </td>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {isFullDesc === item._id
                          ? item.description
                          : item.description.substring(0, 150)}{" "}
                        <span
                          className="text-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFullDesc(
                              isFullDesc === item._id ? null : item._id
                            );
                          }}
                        >
                          {isFullDesc === item._id ? "Read Less" : "Read More"}
                        </span>
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.videoA && (
                          <a
                            style={{
                              color: "#24a0ed",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                            href={item.videoA.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.videoA.name}
                          </a>
                        )}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.videoB && (
                          <a
                            style={{
                              color: "#24a0ed",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                            href={item.videoB.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.videoB.name}
                          </a>
                        )}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.tags &&
                          item.tags.map((item2, index2) => {
                            return `${item2}${
                              index2 < item.tags.length - 1 ? ", " : ""
                            }`;
                          })}
                      </td>
                      <td className="align-middle">
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "22px",
                            marginInline: "5px",
                          }}
                          onClick={() => {
                            setCurrentChallenge(item);
                            setTitle(item.title);
                            setDescription(item.description);
                            setVideoA({
                              value: item.videoA._id,
                              label: item.videoA.name,
                            });
                            setVideoB(
                              item.videoB
                                ? {
                                    value: item.videoB._id,
                                    label: item.videoB.name,
                                  }
                                : null
                            );
                            setSelectedTags(
                              item.tags.map((element) => {
                                return {
                                  value: element,
                                  label: element,
                                };
                              })
                            );
                            setVideoTypeA("select");
                            setVideoTypeB("select");
                            setEdit(true);
                            setNewChallenge(true);
                          }}
                          className="material-icons"
                        >
                          edit
                        </span>

                        <span
                          style={{
                            cursor: "pointer",
                            marginInline: "5px",
                            fontSize: "22px",
                          }}
                          onClick={() => deleteChallenge(item._id)}
                          className="material-icons"
                        >
                          delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </table>

          {currentChallenges.length === 0 && <span>No results</span>}
        </div>
      </div>

      <Modal
        isOpen={newChallenge}
        onRequestClose={() => {
          setNewChallenge(false);
          setImage(null);
          setTitle(null);
          setDescription(null);
          setVideoA(null);
          setVideoB(null);
          setFileA(null);
          setFileB(null);
          setVideoTypeA(null);
          setVideoTypeB(null);
          setSelectedTags([]);
          setCurrentChallenge(null);
        }}
        style={[
          customStyles,
          {
            content: {
              width: "auto",
              maxHeight: "80vh",
            },
          },
        ]}
      >
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "900", flex: 1 }}>
              {currentChallenge ? "Update Challenge" : "Create New Challenge"}
            </h3>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNewChallenge(false);
                setImage(null);
                setTitle(null);
                setDescription(null);
                setVideoA(null);
                setVideoB(null);
                setFileA(null);
                setFileB(null);
                setVideoTypeA(null);
                setVideoTypeB(null);
                setSelectedTags([]);
                setCurrentChallenge(null);
              }}
              className="btn material-icons"
            >
              cancel
            </button>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Thumbnail <span style={{ color: "red" }}>*</span>
                </label>
                <div
                  style={{
                    height: "180px",
                    width: "300px",
                    position: "relative",
                    marginTop: "10px",
                  }}
                >
                  <img
                    id="picker"
                    onClick={() => {
                      if (!f) {
                        f = document.createElement("input");
                        f.id = "picker_input";
                        f.style.display = "none";
                        f.type = "file";
                        f.name = "file";
                        f.accept = "image/*";
                        f.onchange = (e) => {
                          var files = e.target.files;
                          var filesArray = [].slice.call(files);
                          filesArray.forEach((e) => {
                            setImage(e);
                          });
                        };
                      }
                      f.click();
                    }}
                    src={
                      image
                        ? URL.createObjectURL(image)
                        : currentChallenge
                        ? currentChallenge.thumbnail
                        : require("../../assets/images/thumbnail.jpg")
                    }
                    height={"180px"}
                    width={"300px"}
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      marginLeft: "10px",
                      objectFit: image ? "cover" : "contain",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    }}
                  />

                  {image && (
                    <button
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "-30px",
                        top: "-20px",
                      }}
                      onClick={() => {
                        setImage(null);
                      }}
                      className="btn btn-danger material-icons md-18 md-light"
                    >
                      cancel
                    </button>
                  )}
                </div>
              </div>

              <div style={{ width: "41.5%", marginLeft: "50px", padding: 0 }}>
                <div>
                  <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    style={{ margin: 0 }}
                    defaultValue={title}
                    onChange={(e) => {
                      if (e.target.value) setTitle(e.target.value.trim());
                    }}
                  />
                </div>

                <div className="mt-2">
                  <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                    Description <span style={{ color: "red" }}>*</span>{" "}
                  </label>
                  <textarea
                    className="form-control"
                    style={{
                      height: "130px",
                      resize: "none",
                    }}
                    defaultValue={description}
                    onChange={(e) => {
                      if (e.target.value) setDescription(e.target.value.trim());
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5, marginRight: "20px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Video A <span style={{ color: "red" }}>*</span>{" "}
                  <span
                    style={{
                      color: "#c2c2c2",
                      fontWeight: "300",
                      fontStyle: "italic",
                      fontSize: "12px",
                    }}
                  >
                    (Upload or Select uploaded videos)
                  </span>
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {videoTypeA !== "select" && (
                    <div
                      style={{
                        height: fileA && !videoUploadingA ? "140px" : "auto",
                        width: fileA || !videoA ? "240px" : "100%",
                        marginInlineEnd: "50px",
                        alignSelf: "center",
                      }}
                    >
                      {!fileA && !videoA && (
                        <img
                          id="picker"
                          onClick={() => {
                            if (!f) {
                              f = document.createElement("input");
                              f.id = "picker_input";
                              f.style.display = "none";
                              f.type = "file";
                              f.name = "file";
                              f.accept = "video/mp4,video/x-m4v,video/*";
                              f.onchange = (e) => {
                                var files = e.target.files;
                                var filesArray = [].slice.call(files);
                                filesArray.forEach((e) => {
                                  setFileA(e);
                                  setVideoTypeA("upload");
                                });
                              };
                            }
                            f.click();
                          }}
                          src={require("../../assets/images/video_thumbnail.jpg")}
                          height={"140px"}
                          width={"240px"}
                          style={{
                            borderRadius: "10px",
                            cursor: "pointer",
                            objectFit: image ? "cover" : "contain",
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      )}

                      {fileA && !videoUploadingA && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <video
                            style={{
                              alignSelf: "center",
                              marginInlineEnd: "10px",
                            }}
                            width="240"
                            height="140"
                            controls
                          >
                            <source src={URL.createObjectURL(fileA)} />
                            Your browser does not support the video tag.
                          </video>

                          <button
                            style={{
                              cursor: "pointer",
                              marginInlineEnd: "10px",
                              alignSelf: "center",
                            }}
                            onClick={() => {
                              setFileA(null);
                              setVideoTypeA(null);
                            }}
                            className="btn btn-danger material-icons md-18 md-light"
                          >
                            cancel
                          </button>

                          <button
                            style={{
                              cursor: "pointer",
                              alignSelf: "center",
                            }}
                            onClick={() => {
                              uploadVideo(fileA, "A");
                              setVideoUploadingA(true);
                            }}
                            className="btn btn-primary material-icons md-18 md-light"
                          >
                            upload
                          </button>
                        </div>
                      )}

                      {(videoUploadingA ||
                        (videoTypeA === "upload" && videoA)) && (
                        <div>
                          {!videoA && (
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${videoProgressA}%` }}
                                aria-valuenow={videoProgressA}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {videoProgressA}%
                              </div>
                            </div>
                          )}

                          {videoA && (
                            <>
                              <button
                                disabled
                                className="btn btn-success material-icons md-18 md-light"
                              >
                                done
                              </button>
                              <label
                                style={{
                                  color: "green",
                                  marginInlineStart: "10px",
                                }}
                              >
                                Uploaded
                              </label>
                              <br />
                            </>
                          )}

                          <label>{fileA ? fileA.name : videoA.label}</label>
                        </div>
                      )}
                    </div>
                  )}

                  {!videoTypeA && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginInlineEnd: "50px",
                      }}
                    >
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                    </div>
                  )}

                  {videoTypeA !== "upload" && (
                    <div>
                      <Select
                        isMulti={false}
                        defaultValue={videoA}
                        onChange={(item) => {
                          setVideoA(item);
                          setVideoTypeA("select");
                        }}
                        styles={{
                          width: "100%",
                          height: "45px",
                        }}
                        options={videos}
                        placeholder="Select video"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ flex: 0.5, marginRight: "20px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Video B{" "}
                  <span
                    style={{
                      color: "#c2c2c2",
                      fontWeight: "300",
                      fontStyle: "italic",
                      fontSize: "12px",
                    }}
                  >
                    (Upload or Select uploaded videos)
                  </span>
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {videoTypeB !== "select" && (
                    <div
                      style={{
                        height: fileB && !videoUploadingB ? "140px" : "auto",
                        width: fileB || !videoB ? "240px" : "100%",
                        marginInlineEnd: "50px",
                        alignSelf: "center",
                      }}
                    >
                      {!fileB && !videoB && (
                        <img
                          id="picker"
                          onClick={() => {
                            if (!f) {
                              f = document.createElement("input");
                              f.id = "picker_input";
                              f.style.display = "none";
                              f.type = "file";
                              f.name = "file";
                              f.accept = "video/mp4,video/x-m4v,video/*";
                              f.onchange = (e) => {
                                var files = e.target.files;
                                var filesArray = [].slice.call(files);
                                filesArray.forEach((e) => {
                                  setFileB(e);
                                  setVideoTypeB("upload");
                                });
                              };
                            }
                            f.click();
                          }}
                          src={require("../../assets/images/video_thumbnail.jpg")}
                          height={"140px"}
                          width={"240px"}
                          style={{
                            borderRadius: "10px",
                            cursor: "pointer",
                            objectFit: fileB ? "cover" : "contain",
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      )}

                      {fileB && !videoUploadingB && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <video
                            style={{
                              alignSelf: "center",
                              marginInlineEnd: "10px",
                            }}
                            width="240"
                            height="140"
                            controls
                          >
                            <source src={URL.createObjectURL(fileB)} />
                            Your browser does not support the video tag.
                          </video>

                          <button
                            style={{
                              cursor: "pointer",
                              marginInlineEnd: "10px",
                              alignSelf: "center",
                            }}
                            onClick={() => {
                              setFileB(null);
                              setVideoTypeB(null);
                            }}
                            className="btn btn-danger material-icons md-18 md-light"
                          >
                            cancel
                          </button>

                          <button
                            style={{
                              cursor: "pointer",
                              alignSelf: "center",
                            }}
                            onClick={() => {
                              uploadVideo(fileB, "B");
                              setVideoUploadingB(true);
                            }}
                            className="btn btn-primary material-icons md-18 md-light"
                          >
                            upload
                          </button>
                        </div>
                      )}

                      {(videoUploadingB ||
                        (videoTypeB === "upload" && videoB)) && (
                        <div>
                          {!videoB && (
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${videoProgressB}%` }}
                                aria-valuenow={videoProgressB}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {videoProgressB}%
                              </div>
                            </div>
                          )}

                          {videoB && (
                            <>
                              <button
                                disabled
                                className="btn btn-success material-icons md-18 md-light"
                              >
                                done
                              </button>
                              <label
                                style={{
                                  color: "green",
                                  marginInlineStart: "10px",
                                }}
                              >
                                Uploaded
                              </label>
                              <br />
                            </>
                          )}

                          <label>{fileB ? fileB.name : videoB.label}</label>
                        </div>
                      )}
                    </div>
                  )}

                  {!videoTypeB && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginInlineEnd: "50px",
                      }}
                    >
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                      <span style={{ color: "gray" }}>|</span>
                    </div>
                  )}

                  {videoTypeB !== "upload" && (
                    <div>
                      <Select
                        isMulti={false}
                        defaultValue={videoB}
                        onChange={(item) => {
                          setVideoB(item);
                          setVideoTypeB("select");
                        }}
                        styles={{
                          width: "100%",
                          height: "45px",
                        }}
                        options={videos}
                        placeholder="Select video"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ width: "50%", marginTop: "10px", padding: 0 }}>
              <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                Tags <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                isMulti
                defaultValue={selectedTags}
                onChange={setSelectedTags}
                styles={{ width: "100%", height: "45px" }}
                options={Constants.tags()}
                placeholder="Select tags"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <button
              style={{ alignSelf: "center", marginTop: "25px", width: "30%" }}
              className="btn btn-primary"
              onClick={() => {
                setNewChallenge(false);
                if (!image) {
                  editChallenge();
                } else {
                  setUploading(true);
                }
              }}
              disabled={
                loading ||
                title === "" ||
                !videoA ||
                selectedTags.length === 0 ||
                (isEdit ? !currentChallenge.thumbnail : !image)
              }
            >
              {currentChallenge ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Modal>

      {uploading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: "40%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              borderColor: "#ccc",
              borderWidth: "1px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h6 style={{ fontWeight: "600", flex: 1 }}>Uploading</h6>
            </div>
            <hr />

            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={modalIsOpen} setLoading={closeModal} />
    </>
  );
}
