import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Constants from "../../utils/Constants";
import Loader from "../../components/Loader";
import AWS from "aws-sdk";
import Modal from "react-modal";
import moment from "moment";

export default function Videos() {
  let token = localStorage.getItem("token");

  const [allVideos, setAllVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newVideo, setNewVideo] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [newName, setNewName] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [progress, setProgress] = useState(0);

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(videos.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentVideos = videos.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    if (uploading) {
      processUpload();
    }
  }, [uploading]);

  useEffect(() => {
    if (currentVideos.length > 0) {
      currentVideos.forEach((item, index) => {
        var td_name = document.getElementById(`name-${index}`);
        var td_url = document.getElementById(`url-${index}`);

        td_name.addEventListener("input", function () {
          setNewName(td_name.innerHTML);
        });

        td_url.addEventListener("input", function () {
          setNewName(td_url.innerHTML);
        });
      });
    }
  }, [currentVideos]);

  const fetchVideos = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/videos`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setVideos(res.data.data.sort(Constants.sortList));
          setAllVideos(res.data.data.sort(Constants.sortList));
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const addVideo = async (name, url) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/videos/`,
        {
          name: name,
          url: url,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setNewName(null);
          setNewUrl(null);
          fetchVideos();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const editVideo = async (id, name, url) => {
    setLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/videos/${id}`,
        {
          name: name,
          url: url,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setNewName(null);
          setNewUrl(null);
          fetchVideos();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const deleteVideo = async (id) => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/videos/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          fetchVideos();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  let handleChange = (e) => {
    var files = e.target.files;
    var filesArray = [].slice.call(files);
    filesArray.forEach((e) => {
      setFile(e);
    });
  };

  const processUpload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `videos/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setUploading(false);
        setFile(null);
        setProgress(0);
        toast.error("Error:", err);
      } else {
        setUploading(false);
        setFile(null);
        setProgress(0);
        toast.success("Video uploaded");
        addVideo(
          data.Location.substring(data.Location.lastIndexOf("/") + 1),
          data.Location
        );
      }
    }).on("httpUploadProgress", (progress) => {
      setProgress(Math.round((progress.loaded / progress.total) * 100));
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="p-3">
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <h5 style={{ fontWeight: "600", flex: 1 }}>Videos</h5>

          <span
            onClick={() => {
              fetchVideos();
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            refresh
          </span>

          <span
            onClick={() => {
              setNewVideo(true);
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            add
          </span>

          <input
            style={{ width: "30%" }}
            type="text"
            className="search form-control ml-2"
            placeholder="Search by filename..."
            onChange={(e) => {
              if (e.target.value.trim().length > 0) {
                setVideos(
                  videos.filter((v) =>
                    v.name
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                  )
                );
              } else {
                setVideos(allVideos);
              }
            }}
          />
        </div>

        <div
          className="mt-4"
          style={{
            ...(videos.length === 0 && {
              width: "100%",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }),
          }}
        >
          {/* Pagination */}
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                className="page-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </span>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                onClick={() => handlePageChange(index + 1)}
                key={index}
                style={{
                  width: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${
                    currentPage === index + 1 ? "#007BFF" : "#E9ECEF"
                  }`,
                  backgroundColor:
                    currentPage === index + 1 ? "#007BFF" : "white",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: currentPage === index + 1 ? "white" : "#007BFF",
                  }}
                >
                  {index + 1}
                </span>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ cursor: "pointer" }}
              >
                Next
              </span>
            </li>
          </ul>

          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-bordered table-hover table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          >
            <colgroup>
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "25%" }} />
              <col span="1" style={{ width: "35%" }} />
              <col span="1" style={{ width: "13.5%" }} />
              <col span="1" style={{ width: "13.5%" }} />
              <col span="1" style={{ width: "8%" }} />
            </colgroup>

            <thead className="thead-dark">
              <tr>
                <th
                  width="100px"
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  #
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  File Name
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Video Link
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Created At
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Modified At
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Actions</th>
              </tr>
            </thead>
            {currentVideos.length > 0 ? (
              <tbody>
                {currentVideos.map((item, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                        }}
                      >{`${
                        itemsPerPage * (currentPage - 1) + (index + 1)
                      }`}</td>
                      <td
                        className="align-middle"
                        id={`name-${index}`}
                        contentEditable={isEditing}
                        onChange={setNewName}
                        style={{
                          ...(isEditing && {
                            backgroundColor: "#fff",
                            color: "black",
                          }),
                          fontSize: "12px",
                          fontWeight: "300",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        className="align-middle"
                        id={`url-${index}`}
                        contentEditable={isEditing}
                        onInput={(e) => {
                          console.log(e.nativeEvent);
                        }}
                        onChange={setNewUrl}
                        style={{
                          ...(isEditing && { backgroundColor: "#fff" }),
                          color: "#24a0ed",
                          fontSize: "12px",
                          fontWeight: "300",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        <a href={item.url} target="_blank" onClick={() => {}}>
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {moment(item.createdAt).format("DD/MM/yyyy - h:mm A")}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {moment(item.updatedAt).format("DD/MM/yyyy - h:mm A")}
                      </td>
                      <td className="align-middle">
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "22px",
                            marginInline: "5px",
                          }}
                          onClick={() => {
                            if (!isEditing) {
                              setEditing(true);
                            } else {
                              setEditing(false);

                              if (
                                newName !== item.name ||
                                newUrl !== item.url
                              ) {
                                editVideo(
                                  item._id,
                                  newName ? newName : item.name,
                                  newUrl ? newUrl : item.url
                                );
                              }
                            }
                          }}
                          className="material-icons"
                        >
                          edit
                        </span>

                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "22px",
                            marginInline: "5px",
                          }}
                          onClick={() => deleteVideo(item._id)}
                          className="material-icons"
                        >
                          delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </table>

          {currentVideos.length === 0 && <span>No results</span>}
        </div>
      </div>

      <Modal
        isOpen={newVideo}
        onRequestClose={() => {
          setNewVideo(false);
          setFile(null);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ backgroundColor: "#fff", width: "600px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "900", flex: 1 }}>Upload Video</h3>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNewVideo(false);
                setFile(null);
              }}
              className="btn material-icons"
            >
              cancel
            </button>
          </div>
          <hr />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              type="file"
              title="Select Video"
              accept="video/mp4,video/x-m4v,video/*"
              onChange={(e) => handleChange(e)}
            />

            {file && (
              <video
                style={{ alignSelf: "center" }}
                width="320"
                height="240"
                controls
              >
                <source src={URL.createObjectURL(file)} />
                Your browser does not support the video tag.
              </video>
            )}

            <button
              style={{ alignSelf: "center", marginTop: "25px", width: "30%" }}
              className="btn btn-primary"
              onClick={() => {
                setUploading(true);
                setNewVideo(false);
              }}
              disabled={uploading || !file}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>

      {uploading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <div
            style={{
              width: "40%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              borderColor: "#ccc",
              borderWidth: "1px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h6 style={{ fontWeight: "600", flex: 1 }}>Uploading</h6>
              {/* <span
                style={{ cursor: "pointer", fontSize: "22px" }}
                onClick={() => {
                  setUploading(false);
                  setProgress(0);
                  setFile(null);
                }}
                className="material-icons"
              >
                cancel
              </span> */}
            </div>
            <hr />

            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} setLoading={setLoading} />
    </>
  );
}
