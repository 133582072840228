import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-spinner-animated/dist/index.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  LandingPage,
  MainPage,
  Workouts,
  Exercises,
  Videos,
  Users,
  UserDetail,
  Challenges,
} from "./pages";
import AllRoutes from "./utils/Routes";
import ProtectedRoute from "./utils/ProtectedRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={AllRoutes.landing_page} element={<LandingPage />} />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route
            path={AllRoutes.workouts}
            element={
              <ProtectedRoute>
                <Workouts />
              </ProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.exercises}
            element={
              <ProtectedRoute>
                <Exercises />
              </ProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.user}
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.videos}
            element={
              <ProtectedRoute>
                <Videos />
              </ProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.challenges}
            element={
              <ProtectedRoute>
                <Challenges />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${AllRoutes.user_detail}:id`}
          element={
            <ProtectedRoute>
              <UserDetail />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
