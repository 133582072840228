import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Modal from "react-modal";
import moment from "moment";
import Routes from "../../utils/Routes";
import Loader from "../../components/Loader";

export default function UserDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  let token = localStorage.getItem("token");
  let id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

  const [user, setUser] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const difference = user
    ? user.subscription
      ? moment(
          new Date(user.subscription.current_period_end * 1000).toISOString()
        ).diff(moment(), "days")
      : 0
    : -1;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/app/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.data);
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
          console.log(err.response.data.message);
        } else {
          toast.error(err.message);
          console.log(err.message);
        }
        setLoading(false);
      });
  };

  const userActions = async (body) => {
    setLoading(true);
    await axios
      .patch(`${process.env.REACT_APP_BASE_URL}/users/${id}`, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUser(null);
          setUser(res.data.data);
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
          console.log(err.response.data.message);
        } else {
          toast.error(err.message);
          console.log(err.message);
        }
        setLoading(false);
      });
  };

  const removeUser = async () => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/users/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          window.history.replaceState(null, null, "/users");
          navigate(-1);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
          console.log(err.response.data.message);
        } else {
          toast.error(err.message);
          console.log(err.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="pb-5">
        <div className="header-bar">
          <img
            alt="App Logo"
            onClick={() => {
              navigate(`/${Routes.challenges}`);
            }}
            className="header-icon"
            src={require("../../assets/images/logo.png")}
          />
          <h1
            onClick={() => {
              navigate(`/${Routes.challenges}`);
            }}
            className="header-title"
          >
            Offcourtz
          </h1>
        </div>

        {user && (
          <div
            className="p-4"
            style={{
              overflowX: "auto",
              display: fullScreen ? "none" : "block",
            }}
          >
            <div
              className="mb-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "900", flex: 1 }}>Profile</h3>

              <span
                onClick={() => {
                  let body = {
                    isFreeUser: !user.isFreeUser,
                    status: !user.isFreeUser
                      ? "Active"
                      : difference >= 0
                      ? "Active"
                      : "Disabled",
                  };
                  userActions(body);
                }}
                style={{ cursor: "pointer" }}
                className="btn btn-outline-primary"
              >
                {user && user.isFreeUser
                  ? "Revoke Free Access"
                  : "Grant Free Access"}
              </span>

              <span
                onClick={() => {
                  let body = {
                    status:
                      user.status === "Banned"
                        ? user.isFreeUser
                          ? "Active"
                          : difference >= 0
                          ? "Active"
                          : "Disabled"
                        : "Banned",
                  };
                  userActions(body);
                }}
                style={{ cursor: "pointer" }}
                className="btn btn-outline-danger ml-2 "
              >
                {user.status === "Banned" ? "Unblock" : "Block"}
              </span>

              <span
                onClick={() => {
                  removeUser();
                }}
                style={{ cursor: "pointer" }}
                className="btn btn-outline-danger ml-2 material-icons"
              >
                delete
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#FAFAFA",
                borderRadius: "10px",
                padding: "20px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <div id="imageViewer">
                <img
                  id="dp"
                  src={
                    user
                      ? user.image
                      : require("../../assets/images/user_placeholder.png")
                  }
                  alt="Image"
                  style={{ objectFit: user ? "cover" : "contain" }}
                />
                <div id="overlay">
                  <button
                    id="overlay-text"
                    className="btn text-center"
                    onClick={() => setFullScreen(true)}
                  >
                    View image
                  </button>
                </div>
              </div>

              <div>
                <label style={{ fontWeight: "bolder" }}>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.firstName : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  Email Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.email : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.country : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  Account Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.authType : ""}
                />
              </div>

              <div>
                <label style={{ fontWeight: "bolder" }}>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.lastName : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  Date of Birth
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.dob : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  Status
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={user ? user.status : ""}
                />

                <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                  User Joined
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  style={{ margin: 0, width: "20vw" }}
                  defaultValue={
                    user ? moment(user.createdAt).format("DD/MM/YYYY") : ""
                  }
                />
              </div>
            </div>

            {user.subscription ? (
              <div>
                <h3 className="mt-4" style={{ fontWeight: "900" }}>
                  Subscription
                </h3>
                <div
                  className="p-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#FAFAFA",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div>
                    <label style={{ fontWeight: "bolder" }}>Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={user?.subscription.id}
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      Start Date
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={
                        user
                          ? moment(
                              new Date(
                                user?.subscription.current_period_start * 1000
                              ).toISOString()
                            ).format("D/MM/YYYY")
                          : ""
                      }
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      Last Paid Amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={`${
                        user?.subscription?.plan?.amount
                          ? user?.subscription?.plan?.amount
                          : 0
                      }`}
                    />
                  </div>

                  <div>
                    <label style={{ fontWeight: "bolder" }}>Last Invoice</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={user?.subscription.latest_invoice}
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      End Date{" "}
                      {user ? (
                        <label
                          style={{
                            fontSize: "11px",
                            fontWeight: "600",
                            fontStyle: "italic",
                            color:
                              difference > 0
                                ? "green"
                                : difference === 0
                                ? "#ffcc00"
                                : "red",
                          }}
                        >
                          (
                          {difference >= 0
                            ? `${difference} days remaining`
                            : `${-1 * difference} days overdue`}
                          )
                        </label>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={
                        user
                          ? moment(
                              new Date(
                                user?.subscription.current_period_end * 1000
                              ).toISOString()
                            ).format("D/MM/YYYY")
                          : ""
                      }
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      Currency
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={user?.subscription.plan.currency}
                    />
                  </div>

                  <div>
                    <label style={{ fontWeight: "bolder" }}>Customer Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={user?.subscription.customer}
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      Package
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={
                        user?.subscription?.plan?.interval == "month"
                          ? "Monthly"
                          : "Yearly"
                      }
                    />

                    <label style={{ fontWeight: "bolder", marginTop: "10px" }}>
                      Status
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      style={{ margin: 0, width: "20vw" }}
                      defaultValue={user.status}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <i>
                <h6
                  className="mt-4"
                  style={{ fontWeight: "600", color: "#a2a2a2" }}
                >
                  *Not Subscribed yet*
                </h6>
              </i>
            )}
          </div>
        )}
      </div>

      <Modal
        isOpen={fullScreen}
        onRequestClose={() => setFullScreen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 1000,
          }}
        >
          <img
            src={
              user
                ? user.image
                : require("../../assets/images/user_placeholder.png")
            }
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "contain" }}
          />

          <span
            style={{
              position: "absolute",
              color: "white",
              top: "50px",
              right: "50px",
              cursor: "pointer",
              fontSize: "22px",
            }}
            onClick={() => setFullScreen(false)}
            className="material-icons"
          >
            cancel
          </span>
        </div>
      </Modal>

      <Loader loading={loading} setLoading={setLoading} />
    </>
  );
}
