import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { useEffect } from "react";
import Constants from "../../utils/Constants";
import Loader from "../../components/Loader";
import Select from "react-select";
import AWS from "aws-sdk";

export default function Workouts() {
  let token = localStorage.getItem("token");

  const emptyVideoObject = {
    videoA: {
      video: null,
      file: null,
      type: null,
      progress: 0,
      uploading: false,
    },
    videoB: {
      video: null,
      file: null,
      type: null,
      progress: 0,
      uploading: false,
    },
    title: "",
    description: "",
  };
  const types = [
    { value: "easy", label: "Easy" },
    { value: "medium", label: "Medium" },
    { value: "hard", label: "Hard" },
    { value: "weekly", label: "Weekly" },
  ];
  const durations = [
    { value: "5 Mins", label: "5 Mins" },
    { value: "10 Mins", label: "10 Mins" },
    { value: "20 Mins", label: "20 Mins" },
    { value: "30 Mins", label: "30 Mins" },
    { value: "1 Hour", label: "1 Hour" },
    { value: "More than hour", label: "More than hour" },
  ];
  const [allWorkouts, setAllWorkouts] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newWorkout, setNewWorkout] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [videos, setVideos] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([emptyVideoObject]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const [title, setTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [progress, setProgress] = useState(0);
  var f = null;

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(workouts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentWorkouts = workouts.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    fetchWorkouts();
    fetchVideos();
  }, []);

  useEffect(() => {
    if (loading) {
      openModal();
    } else {
      closeModal();
    }
  }, [loading]);

  useEffect(() => {
    if (uploading) {
      processUpload();
    }
  }, [uploading]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fetchWorkouts = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/workouts/admin`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setWorkouts(res.data.data.sort(Constants.sortList));
          setAllWorkouts(res.data.data.sort(Constants.sortList));
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const fetchVideos = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/videos`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let data = [];
          res.data.data.forEach((item, index) => {
            let obj = {
              value: item._id,
              label: item.name,
            };
            data.push(obj);
          });
          setVideos(data);
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const addVideo = async (name, url, uploader, index) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/videos/`,
        {
          name: name,
          url: url,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let result = {
            value: res.data.data._id,
            label: name,
          };
          if (uploader === "A") {
            selectedVideos[index].videoA.video = result;
          } else {
            selectedVideos[index].videoB.video = result;
          }
          setSelectedVideos([...selectedVideos]);
          fetchVideos();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
      });
  };

  const addWorkout = async (url) => {
    setLoading(true);
    let videosToSend = [];
    let tagsToSend = [];

    for (let [index, item] of selectedVideos.entries()) {
      let video = {
        videoA: item.videoA.video.value,
        videoB: item.videoB.video ? item.videoB.video.value : null,
        title: item.title,
        description: item.description,
      };
      videosToSend.push(video);
    }

    selectedTags.forEach((item, index) => {
      tagsToSend.push(item.value);
    });

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/workouts`,
        {
          type: selectedType.value,
          title: title,
          thumbnail: url,
          videos: videosToSend,
          duration: selectedDuration.value,
          tags: tagsToSend,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setImage(null);
          setTitle(null);
          setSelectedType(null);
          setSelectedDuration(null);
          setSelectedTags([]);
          setSelectedVideos([emptyVideoObject]);
          fetchWorkouts();
          setLoading(false);
        } else {
          setImage(null);
          setTitle(null);
          setSelectedType(null);
          setSelectedDuration(null);
          setSelectedTags([]);
          setSelectedVideos([emptyVideoObject]);
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const editWorkout = async (url = "") => {
    setLoading(true);
    let videosToSend = [];
    let tagsToSend = [];

    for (let [index, item] of selectedVideos.entries()) {
      let video = {
        videoA: item.videoA.video.value,
        videoB: item.videoB.video ? item.videoB.video.value : null,
        title: item.title,
        description: item.description,
      };
      videosToSend.push(video);
    }

    selectedTags.forEach((item, index) => {
      tagsToSend.push(item.value);
    });

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/workouts/${currentWorkout._id}`,
        {
          type: selectedType.value,
          title: title,
          thumbnail: url ? url : currentWorkout.thumbnail,
          videos: videosToSend,
          duration: selectedDuration.value,
          tags: tagsToSend,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setTitle(null);
          setImage(null);
          setSelectedType(null);
          setSelectedDuration(null);
          setSelectedTags([]);
          setSelectedVideos([emptyVideoObject]);
          setLoading(false);
          fetchWorkouts();
          setCurrentWorkout(null);
          setNewWorkout(false);
          setEdit(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const deleteWorkout = async (id) => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/workouts/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          fetchWorkouts();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  const processUpload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `workout-images/${image.name}`,
      Body: image,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setUploading(false);
        setProgress(0);
        setImage(null);
        setSelectedType(null);
        setSelectedDuration(null);
        toast.error("Error:::" + err);
      } else {
        setUploading(false);
        setProgress(0);
        toast.success("Image uploaded");
        if (isEdit) {
          editWorkout(data.Location);
        } else {
          addWorkout(data.Location);
        }
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      setProgress(uploadedPercentage);
      console.log("Upload progress:", uploadedPercentage);
    });
  };

  const uploadVideo = (file, uploader, index) => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `videos/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        toast.error(err);
        if (uploader === "A") {
          selectedVideos[index].videoA.uploading = false;
        } else {
          selectedVideos[index].videoB.uploading = false;
        }
        setSelectedVideos([...selectedVideos]);
      } else {
        if (uploader === "A") {
          selectedVideos[index].videoA.uploading = false;
          selectedVideos[index].videoA.progress = 0;
          selectedVideos[index].videoA.file = null;
        } else {
          selectedVideos[index].videoB.uploading = false;
          selectedVideos[index].videoB.progress = 0;
          selectedVideos[index].videoB.file = null;
        }
        setSelectedVideos([...selectedVideos]);
        addVideo(
          data.Location.substring(data.Location.lastIndexOf("/") + 1),
          data.Location,
          uploader,
          index
        );
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      if (uploader === "A") {
        selectedVideos[index].videoA.progress = uploadedPercentage;
      } else {
        selectedVideos[index].videoB.progress = uploadedPercentage;
      }
      setSelectedVideos([...selectedVideos]);
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="p-3">
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <h5 style={{ fontWeight: "600", flex: 1 }}>Workouts</h5>

          <span
            onClick={() => {
              fetchWorkouts();
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            refresh
          </span>

          <span
            onClick={() => {
              setNewWorkout(true);
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            add
          </span>

          <input
            style={{ width: "30%" }}
            type="text"
            className="search form-control ml-2"
            placeholder="Search by title..."
            onChange={(e) => {
              if (e.target.value.trim().length > 0) {
                setWorkouts(
                  workouts.filter((v) =>
                    v.title
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                  )
                );
              } else {
                setWorkouts(allWorkouts);
              }
            }}
          />
        </div>

        <div
          className="mt-4"
          style={{
            ...(workouts.length === 0 && {
              width: "100%",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }),
          }}
        >
          {/* Pagination */}
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                className="page-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </span>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                onClick={() => handlePageChange(index + 1)}
                key={index}
                style={{
                  width: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${
                    currentPage === index + 1 ? "#007BFF" : "#E9ECEF"
                  }`,
                  backgroundColor:
                    currentPage === index + 1 ? "#007BFF" : "white",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: currentPage === index + 1 ? "white" : "#007BFF",
                  }}
                >
                  {index + 1}
                </span>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ cursor: "pointer" }}
              >
                Next
              </span>
            </li>
          </ul>

          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-bordered table-hover table-responsive-xl table-responsive-lg table-responsive-mdtable-responsive-sm"
          >
            <colgroup>
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "auto" }} />
              <col span="1" style={{ width: "12%" }} />
              <col span="1" style={{ width: "7%" }} />
              <col span="1" style={{ width: "13%" }} />
              <col span="1" style={{ width: "30%" }} />
              <col span="1" style={{ width: "10%" }} />
              <col span="1" style={{ width: "8%" }} />
            </colgroup>

            <thead className="thead-dark">
              <tr>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>#</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Image</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Title</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Type</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Duration
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Videos</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Tags</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Actions</th>
              </tr>
            </thead>
            {currentWorkouts.length > 0 ? (
              <tbody>
                {currentWorkouts.map((item, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >{`${
                        itemsPerPage * (currentPage - 1) + (index + 1)
                      }`}</td>
                      <td className="text-center align-middle">
                        <img
                          src={item.thumbnail}
                          style={{
                            height: "90px",
                            width: "150px",
                            borderRadius: "5px",
                          }}
                        />
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "800" }}
                      >
                        {item.title}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.duration}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.videos.length > 0 &&
                          item.videos.map((item2, index2) => {
                            return (
                              <div>
                                <a
                                  style={{
                                    color: "#24a0ed",
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                  href={item2.videoA.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item2.videoA.name}
                                </a>
                                {item2.videoB ? ", " : ""}
                                {item2.videoB && (
                                  <a
                                    style={{
                                      color: "#24a0ed",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                    href={item2.videoB.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item2.videoB.name}
                                  </a>
                                )}
                                {index2 < item.videos.length - 1 && <br />}
                                {index2 < item.videos.length - 1 && <br />}
                              </div>
                            );
                          })}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.tags &&
                          item.tags.map((item2, index2) => {
                            return `${item2}${
                              index2 < item.tags.length - 1 ? ", " : ""
                            }`;
                          })}
                      </td>
                      <td className="align-middle">
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "22px",
                            marginInline: "5px",
                          }}
                          onClick={() => {
                            setCurrentWorkout(item);
                            setTitle(item.title);
                            setSelectedType({
                              value: item.type,
                              label:
                                item.type.charAt(0).toUpperCase() +
                                item.type.slice(1),
                            });
                            setSelectedDuration({
                              value: item.duration,
                              label:
                                item.duration.charAt(0).toUpperCase() +
                                item.duration.slice(1),
                            });
                            setSelectedTags(
                              item.tags.map((element) => {
                                return {
                                  value: element,
                                  label: element,
                                };
                              })
                            );

                            let videosToEdit = [];
                            item.videos.forEach((item3, index3) => {
                              let obj = JSON.parse(
                                JSON.stringify(emptyVideoObject)
                              );
                              obj.videoA.video = {
                                value: item3.videoA._id,
                                label: item3.videoA.name,
                              };
                              obj.videoA.type = "select";
                              if (item3.videoB) {
                                obj.videoB.video = {
                                  value: item3.videoB._id,
                                  label: item3.videoB.name,
                                };
                              }
                              obj.videoB.type = item3.videoB ? "select" : null;
                              obj.title = item3.title;
                              obj.description = item3.description;
                              videosToEdit.push(obj);
                            });

                            setSelectedVideos([...videosToEdit]);
                            setEdit(true);
                            setNewWorkout(true);
                          }}
                          className="material-icons"
                        >
                          edit
                        </span>

                        <span
                          style={{
                            cursor: "pointer",
                            marginInline: "5px",
                            fontSize: "22px",
                          }}
                          onClick={() => deleteWorkout(item._id)}
                          className="material-icons"
                        >
                          delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </table>

          {currentWorkouts.length === 0 && <span>No results</span>}
        </div>
      </div>

      <Modal
        isOpen={newWorkout}
        onRequestClose={() => {
          setNewWorkout(false);
          setTitle(null);
          setImage(null);
          setSelectedType(null);
          setSelectedDuration(null);
          setSelectedTags([]);
          setSelectedVideos([emptyVideoObject]);
          setCurrentWorkout(null);
        }}
        style={[
          customStyles,
          {
            content: {
              width: "auto",
              maxHeight: "80vh",
            },
          },
        ]}
      >
        <div style={{ backgroundColor: "#fff" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "900", flex: 1 }}>
              {currentWorkout ? "Update Workout" : "Create New Workout"}
            </h3>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNewWorkout(false);
                setTitle(null);
                setImage(null);
                setSelectedType(null);
                setSelectedDuration(null);
                setSelectedTags([]);
                setSelectedVideos([emptyVideoObject]);
                setCurrentWorkout(null);
              }}
              className="btn material-icons"
            >
              cancel
            </button>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ alignSelf: "center" }}>
              <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                Thumbnail <span style={{ color: "red" }}>*</span>
              </label>
              <div
                style={{
                  height: "180px",
                  width: "300px",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <img
                  id="picker"
                  onClick={() => {
                    if (!f) {
                      f = document.createElement("input");
                      f.id = "picker_input";
                      f.style.display = "none";
                      f.type = "file";
                      f.name = "file";
                      f.accept = "image/*";
                      f.onchange = (e) => {
                        var files = e.target.files;
                        var filesArray = [].slice.call(files);
                        filesArray.forEach((e) => {
                          setImage(e);
                        });
                      };
                    }
                    f.click();
                  }}
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : currentWorkout
                      ? currentWorkout.thumbnail
                      : require("../../assets/images/thumbnail.jpg")
                  }
                  height={"180px"}
                  width={"300px"}
                  style={{
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    objectFit: image ? "cover" : "contain",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                  }}
                />

                {image && (
                  <button
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "-30px",
                      top: "-20px",
                    }}
                    onClick={() => {
                      setImage(null);
                    }}
                    className="btn btn-danger material-icons md-18 md-light"
                  >
                    cancel
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 0.5,
                alignItems: "center",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <div style={{ width: "100%", margin: 0, padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Title <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="search form-control"
                  placeholder=""
                  defaultValue={title}
                  onChange={(e) => {
                    if (e.target.value.trim()) setTitle(e.target.value.trim());
                  }}
                />
              </div>

              <div style={{ width: "100%", marginTop: "10px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Tags <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti
                  defaultValue={selectedTags}
                  onChange={setSelectedTags}
                  styles={{ width: "100%", height: "45px" }}
                  options={Constants.tags()}
                  placeholder="Select tags"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 0.5,
                alignItems: "center",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <div style={{ width: "100%", margin: 0, padding: 0 }}>
                <label style={{ fontWeight: "bolder" }}>
                  Workout Level <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti={false}
                  defaultValue={selectedType}
                  onChange={setSelectedType}
                  styles={{ width: "100%", height: "45px" }}
                  options={types}
                  placeholder="Select type"
                />
              </div>

              <div style={{ width: "100%", marginTop: "10px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Duration <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti={false}
                  defaultValue={selectedDuration}
                  onChange={setSelectedDuration}
                  styles={{ width: "100%", height: "45px" }}
                  options={durations}
                  placeholder="Select duration"
                />
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <label style={{ fontWeight: "bolder", flex: 1 }}>
                Videos (1-7 pair of videos){" "}
                <span style={{ color: "red" }}>*</span>
              </label>

              <span
                onClick={() => {
                  if (selectedVideos.length < 7) {
                    setSelectedVideos([...selectedVideos, emptyVideoObject]);
                  }
                }}
                style={{ cursor: "pointer" }}
                className="btn btn-outline-primary"
              >
                Add New Pair
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                overflowX: "scroll",
              }}
            >
              {selectedVideos.map((item, index) => {
                return (
                  <div
                    style={{
                      minWidth: "30vw",
                      margin: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <h5 style={{ flex: 1 }}>
                        <b>
                          <u>Video pair : {index + 1}</u>
                        </b>
                      </h5>

                      {selectedVideos.length > 1 && (
                        <button
                          style={{
                            cursor: "pointer",
                            marginInlineEnd: "10px",
                            alignSelf: "center",
                          }}
                          onClick={() => {
                            setSelectedVideos(
                              selectedVideos.filter((s, i) => i !== index)
                            );
                          }}
                          className="btn btn-danger material-icons md-18 md-light"
                        >
                          cancel
                        </button>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <label
                        style={{
                          fontWeight: "bolder",
                          marginTop: "20px",
                          marginRight: "20px",
                        }}
                      >
                        Title
                      </label>

                      <input
                        className="form-control"
                        defaultValue={selectedVideos[index].title}
                        onChange={(e) => {
                          if (e.target.value) {
                            selectedVideos[index].title = e.target.value.trim();
                            setSelectedVideos([...selectedVideos]);
                          }
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <label
                        style={{ fontWeight: "bolder", marginRight: "20px" }}
                      >
                        Video A
                      </label>

                      {!selectedVideos[index].videoA.file &&
                        !selectedVideos[index].videoA.video && (
                          <img
                            id="picker"
                            onClick={() => {
                              if (!f) {
                                f = document.createElement("input");
                                f.id = "picker_input";
                                f.style.display = "none";
                                f.type = "file";
                                f.name = "file";
                                f.accept = "video/mp4,video/x-m4v,video/*";
                                f.onchange = (e) => {
                                  var files = e.target.files;
                                  var filesArray = [].slice.call(files);
                                  filesArray.forEach((e) => {
                                    selectedVideos[index].videoA.file = e;
                                    selectedVideos[index].videoA.type =
                                      "upload";
                                    setSelectedVideos([...selectedVideos]);
                                  });
                                };
                              }
                              f.click();
                            }}
                            src={require("../../assets/images/video_thumbnail.jpg")}
                            height={"60px"}
                            width={"90px"}
                            style={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              objectFit: image ? "cover" : "contain",
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                            }}
                          />
                        )}

                      {selectedVideos[index].videoA.file &&
                        !selectedVideos[index].videoA.uploading && (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <video
                              style={{
                                alignSelf: "center",
                                marginInlineEnd: "10px",
                              }}
                              width="90"
                              height="60"
                              controls
                            >
                              <source
                                src={URL.createObjectURL(
                                  selectedVideos[index].videoA.file
                                )}
                              />
                              Your browser does not support the video tag.
                            </video>

                            <button
                              style={{
                                cursor: "pointer",
                                marginInlineEnd: "10px",
                                alignSelf: "center",
                              }}
                              onClick={() => {
                                selectedVideos[index].videoA.file = null;
                                selectedVideos[index].videoA.type = null;
                                setSelectedVideos([...selectedVideos]);
                              }}
                              className="btn btn-danger material-icons md-18 md-light"
                            >
                              cancel
                            </button>

                            <button
                              style={{
                                cursor: "pointer",
                                alignSelf: "center",
                              }}
                              onClick={() => {
                                selectedVideos[index].videoA.uploading = true;
                                setSelectedVideos([...selectedVideos]);
                                uploadVideo(
                                  selectedVideos[index].videoA.file,
                                  "A",
                                  index
                                );
                              }}
                              className="btn btn-primary material-icons md-18 md-light"
                            >
                              upload
                            </button>
                          </div>
                        )}

                      {(selectedVideos[index].videoA.uploading ||
                        (selectedVideos[index].videoA.type === "upload" &&
                          selectedVideos[index].videoA.video)) && (
                        <div>
                          {!selectedVideos[index].videoA.video && (
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${selectedVideos[index].videoA.progress}%`,
                                }}
                                aria-valuenow={
                                  selectedVideos[index].videoA.progress
                                }
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {selectedVideos[index].videoA.progress}%
                              </div>
                            </div>
                          )}

                          {selectedVideos[index].videoA.video && (
                            <>
                              <button
                                disabled
                                className="btn btn-success material-icons md-18 md-light"
                              >
                                done
                              </button>
                              <label
                                style={{
                                  color: "green",
                                  marginInlineStart: "10px",
                                }}
                              >
                                Uploaded
                              </label>
                              <br />
                            </>
                          )}

                          <label>
                            {selectedVideos[index].videoA.file
                              ? selectedVideos[index].videoA.file.name.length >
                                25
                                ? `${selectedVideos[
                                    index
                                  ].videoA.file.name.substring(0, 25)}...`
                                : selectedVideos[index].videoA.file.name
                              : selectedVideos[index].videoA.video.label
                                  .length > 25
                              ? `${selectedVideos[
                                  index
                                ].videoA.video.label.substring(0, 25)}...`
                              : selectedVideos[index].videoA.video.label}
                          </label>
                        </div>
                      )}

                      {!selectedVideos[index].videoA.type && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginInline: "20px",
                          }}
                        >
                          <span style={{ color: "gray" }}>|</span>
                          <span style={{ color: "gray" }}>|</span>
                        </div>
                      )}

                      {selectedVideos[index].videoA.type !== "upload" && (
                        <div>
                          <Select
                            isMulti={false}
                            defaultValue={selectedVideos[index].videoA.video}
                            onChange={(item) => {
                              selectedVideos[index].videoA.video = item;
                              selectedVideos[index].videoA.type = "select";
                              setSelectedVideos([...selectedVideos]);
                            }}
                            styles={{
                              width: "100%",
                              height: "45px",
                            }}
                            options={videos}
                            placeholder="Select video"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <label
                        style={{ fontWeight: "bolder", marginRight: "20px" }}
                      >
                        Video B
                      </label>

                      {!selectedVideos[index].videoB.file &&
                        !selectedVideos[index].videoB.video && (
                          <img
                            id="picker"
                            onClick={() => {
                              if (!f) {
                                f = document.createElement("input");
                                f.id = "picker_input";
                                f.style.display = "none";
                                f.type = "file";
                                f.name = "file";
                                f.accept = "video/mp4,video/x-m4v,video/*";
                                f.onchange = (e) => {
                                  var files = e.target.files;
                                  var filesArray = [].slice.call(files);
                                  filesArray.forEach((e) => {
                                    selectedVideos[index].videoB.file = e;
                                    selectedVideos[index].videoB.type =
                                      "upload";
                                  });
                                  setSelectedVideos([...selectedVideos]);
                                };
                              }
                              f.click();
                            }}
                            src={require("../../assets/images/video_thumbnail.jpg")}
                            height={"60px"}
                            width={"90px"}
                            style={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              objectFit: image ? "cover" : "contain",
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                            }}
                          />
                        )}

                      {selectedVideos[index].videoB.file &&
                        !selectedVideos[index].videoB.uploading && (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <video
                              style={{
                                alignSelf: "center",
                                marginInlineEnd: "10px",
                              }}
                              width="90"
                              height="60"
                              controls
                            >
                              <source
                                src={URL.createObjectURL(
                                  selectedVideos[index].videoB.file
                                )}
                              />
                              Your browser does not support the video tag.
                            </video>

                            <button
                              style={{
                                cursor: "pointer",
                                marginInlineEnd: "10px",
                                alignSelf: "center",
                              }}
                              onClick={() => {
                                selectedVideos[index].videoB.file = null;
                                selectedVideos[index].videoB.type = null;
                                setSelectedVideos([...selectedVideos]);
                              }}
                              className="btn btn-danger material-icons md-18 md-light"
                            >
                              cancel
                            </button>

                            <button
                              style={{
                                cursor: "pointer",
                                alignSelf: "center",
                              }}
                              onClick={() => {
                                selectedVideos[index].videoB.uploading = true;
                                setSelectedVideos([...selectedVideos]);
                                uploadVideo(
                                  selectedVideos[index].videoB.file,
                                  "B",
                                  index
                                );
                              }}
                              className="btn btn-primary material-icons md-18 md-light"
                            >
                              upload
                            </button>
                          </div>
                        )}

                      {(selectedVideos[index].videoB.uploading ||
                        (selectedVideos[index].videoB.type === "upload" &&
                          selectedVideos[index].videoB.video)) && (
                        <div>
                          {!selectedVideos[index].videoB.video && (
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${selectedVideos[index].videoB.progress}%`,
                                }}
                                aria-valuenow={
                                  selectedVideos[index].videoB.progress
                                }
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {selectedVideos[index].videoB.progress}%
                              </div>
                            </div>
                          )}

                          {selectedVideos[index].videoB.video && (
                            <>
                              <button
                                disabled
                                className="btn btn-success material-icons md-18 md-light"
                              >
                                done
                              </button>
                              <label
                                style={{
                                  color: "green",
                                  marginInlineStart: "10px",
                                }}
                              >
                                Uploaded
                              </label>
                              <br />
                            </>
                          )}

                          <label>
                            {selectedVideos[index].videoB.file
                              ? selectedVideos[index].videoB.file.name.length >
                                25
                                ? `${selectedVideos[
                                    index
                                  ].videoB.file.name.substring(0, 25)}...`
                                : selectedVideos[index].videoB.file.name
                              : selectedVideos[index].videoB.video.label
                                  .length > 25
                              ? `${selectedVideos[
                                  index
                                ].videoB.video.label.substring(0, 25)}...`
                              : selectedVideos[index].videoB.video.label}
                          </label>
                        </div>
                      )}

                      {!selectedVideos[index].videoB.type && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginInline: "20px",
                          }}
                        >
                          <span style={{ color: "gray" }}>|</span>
                          <span style={{ color: "gray" }}>|</span>
                        </div>
                      )}

                      {selectedVideos[index].videoB.type !== "upload" && (
                        <div>
                          <Select
                            isMulti={false}
                            defaultValue={selectedVideos[index].videoB.video}
                            onChange={(item) => {
                              selectedVideos[index].videoB.video = item;
                              selectedVideos[index].videoB.type = "select";
                              setSelectedVideos([...selectedVideos]);
                            }}
                            styles={{
                              width: "100%",
                              height: "45px",
                            }}
                            options={videos}
                            placeholder="Select video"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <label
                        style={{ fontWeight: "bolder", marginRight: "20px" }}
                      >
                        Description
                      </label>

                      <textarea
                        className="form-control"
                        style={{
                          height: "60px",
                          resize: "none",
                        }}
                        defaultValue={selectedVideos[index].description}
                        onChange={(e) => {
                          if (e.target.value) {
                            selectedVideos[index].description =
                              e.target.value.trim();
                            setSelectedVideos([...selectedVideos]);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              style={{ alignSelf: "center", marginTop: "25px", width: "50%" }}
              className="btn btn-primary"
              onClick={() => {
                setNewWorkout(false);
                if (!image) {
                  editWorkout();
                } else {
                  setUploading(true);
                }
              }}
              disabled={
                loading ||
                title === "" ||
                !selectedType ||
                !selectedDuration ||
                selectedVideos.filter((sv) => sv.videoA.video && sv.description)
                  .length === 0 ||
                selectedTags.length === 0 ||
                (isEdit ? !currentWorkout.thumbnail : !image)
              }
            >
              {currentWorkout ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Modal>

      {uploading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: "40%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              borderColor: "#ccc",
              borderWidth: "1px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h6 style={{ fontWeight: "600", flex: 1 }}>Uploading</h6>
              {/* <span
                style={{ cursor: "pointer", fontSize: "22px" }}
                onClick={() => {
                  setUploading(false);
                  setProgress(0);
                  setImage(null);
                }}
                className="material-icons"
              >
                cancel
              </span> */}
            </div>
            <hr />

            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}

      <Loader loading={modalIsOpen} setLoading={closeModal} />
    </>
  );
}
