import React, { useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import Routes from "../../utils/Routes";

export default function MainPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = useRef("challenges");
  const [loading, setLoading] = useState(false);
  let tabs = [
    { path: Routes.challenges, name: "Challenges" },
    { path: Routes.workouts, name: "Workouts" },
    { path: Routes.exercises, name: "Exercises" },
    { path: Routes.user, name: "Users" },
    { path: Routes.videos, name: "Videos" },
  ];

  return (
    <>
      <ToastContainer />
      <div>
        <div className="header-bar">
          <img
            alt="App Logo"
            onClick={() => {
              navigate("/workouts");
              // window.location.reload();
            }}
            className="header-icon"
            src={require("../../assets/images/logo.png")}
          />
          <h1
            onClick={() => {
              navigate("/challenges");
            }}
            className="header-title"
          >
            Offcourtz
          </h1>
          <div className="expanded-view" />

          {tabs.map((item) => {
            return (
              <button
                className="btn"
                style={{
                  padding: "7px",
                  color:
                    location.pathname === `/${item.path}` ? "#fff" : "#000",
                  backgroundColor: "#b3b003",
                  marginRight: "10px",
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                onClick={() => {
                  path.current = item.path;
                  navigate(path.current);
                }}
              >
                {item.name}
              </button>
            );
          })}

          <button
            className="btn btn-danger btn-sm btn-xs"
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                localStorage.setItem("isLoggedIn", null);
                localStorage.setItem("admin", null);
                localStorage.setItem("token", null);
                navigate("/", {
                  replace: true,
                });
                setLoading(false);
              }, 1500);
            }}
          >
            Logout
          </button>
        </div>

        <div>
          <Outlet />
          <Loader loading={loading} setLoading={setLoading} />
        </div>
      </div>
    </>
  );
}
