import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Constants from "../../utils/Constants";
import Routes from "../../utils/Routes";
import Loader from "../../components/Loader";
import AWS from "aws-sdk";
import Modal from "react-modal";
import Select from "react-select";
import moment from "moment/moment";

export default function Users() {
  let token = localStorage.getItem("token");

  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [isFreeUser, setFreeUser] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  var f = null;

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(users.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentVideos = users.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (uploading) {
      processUpload();
    }
  }, [uploading]);

  const fetchUsers = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/panel/`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUsers(res.data.data.sort(Constants.sortList));
          setAllUsers(res.data.data.sort(Constants.sortList));
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
          console.log(err.response.data.message);
        } else {
          toast.error(err.message);
          console.log(err.message);
        }
        setLoading(false);
      });
  };

  const addUser = async (image) => {
    setLoading(true);
    let body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      dob: moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      country: country,
      password: password,
      isFreeUser: isFreeUser,
      image: image,
      status: "Active",
    };

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/users`, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setLoading(false);
          fetchUsers();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
          console.log(err.response.data.message);
        } else {
          toast.error(err.message);
          console.log(err.message);
        }
        setLoading(false);
      });
  };

  const processUpload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `images/${image.name}`,
      Body: image,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setUploading(false);
        setProgress(0);
        setFirstName("");
        setLastName("");
        setEmail("");
        setDate("");
        setCountry("");
        setPassword("");
        setImage(null);
        toast.error("Error:::" + err);
      } else {
        setUploading(false);
        setProgress(0);
        toast.success("Image uploaded");
        addUser(data.Location);
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      setProgress(uploadedPercentage);
      console.log("Upload progress:", uploadedPercentage);
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="p-3">
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <h5 style={{ fontWeight: "600", flex: 1 }}>Users</h5>

          <span
            onClick={() => {
              fetchUsers();
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            refresh
          </span>

          <span
            onClick={() => {
              setNewUser(true);
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-outline-primary ml-2 material-icons"
          >
            add
          </span>

          <input
            style={{ width: "30%" }}
            type="text"
            className="search form-control ml-2"
            placeholder="Search by fullname..."
            onChange={(e) => {
              if (e.target.value.trim().length > 0) {
                setUsers(
                  users.filter((v) =>
                    (v.firstName + v.lastName)
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                  )
                );
              } else {
                setUsers(allUsers);
              }
            }}
          />
        </div>

        <div
          className="mt-4"
          style={{
            ...(users.length === 0 && {
              width: "100%",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }),
          }}
        >
          {/* Pagination */}
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                className="page-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </span>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                onClick={() => handlePageChange(index + 1)}
                key={index}
                style={{
                  width: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${
                    currentPage === index + 1 ? "#007BFF" : "#E9ECEF"
                  }`,
                  backgroundColor:
                    currentPage === index + 1 ? "#007BFF" : "white",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: currentPage === index + 1 ? "white" : "#007BFF",
                  }}
                >
                  {index + 1}
                </span>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ cursor: "pointer" }}
              >
                Next
              </span>
            </li>
          </ul>

          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-bordered table-hover table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          >
            <colgroup>
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "7%" }} />
              <col span="1" style={{ width: "20%" }} />
              <col span="1" style={{ width: "25%" }} />
              <col span="1" style={{ width: "14%" }} />
              <col span="1" style={{ width: "15%" }} />
              <col span="1" style={{ width: "7%" }} />
              <col span="1" style={{ width: "7%" }} />
            </colgroup>

            <thead className="thead-dark">
              <tr>
                <th
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  #
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Picture</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Fullname
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Email Address
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>
                  Date of Birth
                </th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Country</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Type</th>
                <th style={{ fontSize: "13px", fontWeight: "500" }}>Status</th>
              </tr>
            </thead>
            {currentVideos.length > 0 ? (
              <tbody>
                {currentVideos.map((item, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                        }}
                      >{`${
                        itemsPerPage * (currentPage - 1) + (index + 1)
                      }`}</td>
                      <td className="align-middle text-center">
                        <a href={`${Routes.user_detail}${item._id}`}>
                          <img
                            src={
                              item.image
                                ? item.image
                                : require("../../assets/images/user_placeholder.png")
                            }
                            style={{
                              height: "60px",
                              width: "60px",
                              borderRadius: "30px",
                            }}
                          />
                        </a>
                      </td>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "900",
                          color: "#24a0ed",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          href={`users/detail/${item._id}`}
                        >{`${item.firstName} ${item.lastName}`}</a>
                      </td>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.dob}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.country}
                      </td>
                      <td
                        className="align-middle"
                        style={{ fontSize: "12px", fontWeight: "300" }}
                      >
                        {item.isFreeUser ? "Free" : "Paid"}
                      </td>
                      <td
                        className="align-middle"
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color:
                            item.status === "Active"
                              ? "green"
                              : item.status === "Banned"
                              ? "red"
                              : item.status === "Disabled"
                              ? "#ffcc00"
                              : "black",
                        }}
                      >
                        {item.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </table>

          {currentVideos.length === 0 && <span>No results</span>}
        </div>
      </div>

      <Modal
        isOpen={newUser}
        onRequestClose={() => {
          setNewUser(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDate("");
          setCountry("");
          setPassword("");
          setImage(null);
        }}
        style={customStyles}
      >
        <div style={{ backgroundColor: "#fff", width: "700px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "900", flex: 1 }}>Create New User</h3>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNewUser(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setDate("");
                setCountry("");
                setPassword("");
                setImage(null);
              }}
              className="btn material-icons"
            >
              cancel
            </button>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ alignSelf: "center" }}>
              <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                Profile Picture <span style={{ color: "red" }}>*</span>
              </label>
              <div
                style={{
                  height: "180px",
                  width: "300px",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <img
                  id="picker"
                  onClick={() => {
                    if (!f) {
                      f = document.createElement("input");
                      f.id = "picker_input";
                      f.style.display = "none";
                      f.type = "file";
                      f.name = "file";
                      f.onchange = (e) => {
                        var files = e.target.files;
                        var filesArray = [].slice.call(files);
                        filesArray.forEach((e) => {
                          setImage(e);
                        });
                      };
                    }
                    f.click();
                  }}
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : require("../../assets/images/thumbnail.jpg")
                  }
                  height={"180px"}
                  width={"300px"}
                  style={{
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    objectFit: image ? "cover" : "contain",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                  }}
                />

                {image && (
                  <button
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "-30px",
                      top: "-20px",
                    }}
                    onClick={() => {
                      setImage(null);
                    }}
                    className="btn btn-danger material-icons md-18 md-light"
                  >
                    cancel
                  </button>
                )}
              </div>
            </div>

            <div
              style={{
                margin: 0,
                padding: 0,
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <label
                style={{
                  fontWeight: "bolder",
                  marginRight: "15px",
                }}
              >
                Type
              </label>
              <Select
                isMulti={false}
                defaultValue={
                  isFreeUser
                    ? { value: "Free", label: "Free" }
                    : { value: "Paid", label: "Paid" }
                }
                onChange={(e) => setFreeUser(e.value === "Free")}
                styles={{ width: "100%", height: "45px" }}
                options={[
                  { label: "Free", value: "Free" },
                  { label: "Paid", value: "Paid" },
                ]}
                placeholder="Select country"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 0.5,
                alignItems: "center",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <div style={{ width: "100%", margin: 0, padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="search form-control"
                  placeholder=""
                  defaultValue={firstName}
                  onChange={(e) => {
                    if (e.target.value.trim())
                      setFirstName(e.target.value.trim());
                  }}
                />
              </div>

              <div style={{ width: "100%", marginTop: "10px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Last Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="search form-control"
                  placeholder=""
                  defaultValue={lastName}
                  onChange={(e) => {
                    if (e.target.value.trim())
                      setLastName(e.target.value.trim());
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 0.5,
                alignItems: "center",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <div style={{ width: "100%", margin: 0, padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Email Address <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"email"}
                  className="search form-control"
                  placeholder=""
                  defaultValue={email}
                  onChange={(e) => {
                    if (e.target.value.trim()) setEmail(e.target.value.trim());
                  }}
                />
              </div>

              <div style={{ width: "100%", marginTop: "10px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"date"}
                  className="search form-control"
                  placeholder=""
                  defaultValue={date}
                  onChange={(e) => {
                    console.log(e);
                    if (e.target.value.trim()) setDate(e.target.value.trim());
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 0.5,
                alignItems: "center",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <div style={{ width: "100%", margin: 0, padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti={false}
                  defaultValue={Constants.getCountries().find(
                    (c) => c.value === country
                  )}
                  onChange={(e) => setCountry(e.value)}
                  styles={{ width: "100%", height: "45px" }}
                  options={Constants.getCountries()}
                  placeholder="Select country"
                />
              </div>

              <div style={{ width: "100%", marginTop: "10px", padding: 0 }}>
                <label style={{ fontWeight: "bolder", marginRight: "10px" }}>
                  Password <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"password"}
                  className="search form-control"
                  placeholder=""
                  defaultValue={password}
                  onChange={(e) => {
                    if (e.target.value.trim())
                      setPassword(e.target.value.trim());
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              style={{ alignSelf: "center", marginTop: "25px", width: "50%" }}
              className="btn btn-primary"
              onClick={() => {
                setNewUser(false);
                setUploading(true);
              }}
              disabled={
                loading ||
                firstName === "" ||
                lastName === "" ||
                email === "" ||
                date === "" ||
                country === "" ||
                !image
              }
            >
              Create
            </button>
          </div>
        </div>
      </Modal>

      {uploading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: "40%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              borderColor: "#ccc",
              borderWidth: "1px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h6 style={{ fontWeight: "600", flex: 1 }}>Uploading</h6>
            </div>
            <hr />

            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}

      <Loader loading={loading} setLoading={setLoading} />
    </>
  );
}
