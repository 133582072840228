import React from "react";
import Modal from "react-modal";

export default function Loader(props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <Modal
        isOpen={props.loading}
        onRequestClose={() => props.setLoading(false)}
        style={customStyles}
      >
        <div style={{ backgroundColor: "#fff" }}>
          <span className="spinner-border spinner-border-xl text-dark" />
        </div>
      </Modal>
    </>
  );
}
