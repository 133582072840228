import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Routes from "../../utils/Routes";
import axios from "axios";

export default function LandingPage(props) {
  const location = useLocation();
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "1") {
      navigate(`/${Routes.challenges}`, { replace: true });
    }
  }, []);

  const startProcessing = async (data) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/login`, {
        email: document.getElementById("email").value,
        password: document.getElementById("password").value,
      })
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("isLoggedIn", "1");
          localStorage.setItem("admin", JSON.stringify(res.data.data));
          localStorage.setItem("token", res.data.token);
          setLoading(false);
          if (location.state && location.state.id)
            navigate(`/${Routes.challenges}`, {
              state: {
                id: location.state.id,
              },
            });
          else navigate(`/${Routes.challenges}`, { replace: true });
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page landing-page">
        <div
          className="d-flex flex-column align-items-center flex-fill"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            paddingTop: "30px",
          }}
        >
          <div className="text-light" style={{fontSize: "48px", fontWeight: 'bold'}}>OFFCOURTZ</div>
          <div className="text-light text-center" style={{fontSize: "18px", fontWeight: '400'}}>
            Welcome to Offcourtz Admin Panel
          </div>

          <div id="input-area" className="p-3 rounded-3 shadow-sm input-area">
            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit(startProcessing)}
            >
              <label className="h4 text-light m-0 p-0">Sign In</label>
              <label className="h6 text-light m-0 p-0">
                <small>Sign in to your personal account</small>
              </label>

              <div>
                <label className="h6 text-light mt-3">
                  <small>Email Address</small>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="email"
                  type="email"
                  name="email"
                  required
                />

                <label className="h6 text-light mt-1">
                  <small>Password</small>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="password"
                  type="password"
                  name="password"
                  required
                />

                <button
                  className="btn rounded-3 bg-dark text-light w-100 mt-3 py-1"
                  type="submit"
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm text-light"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

const Footer = () => {
  return (
    <div
      style={{
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#b3b003",
        padding: "15px",
      }}
    >
      <p className="footer-p" style={{ color: "#fff", margin: 0, padding: 0 }}>
        Powered by{"   "}
        <a
          className="footer-a"
          style={{ color: "#fff", marginRight: "5px" }}
          href="https://bitforcesolutions.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bitforce
        </a>
      </p>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "5px",
          borderRadius: "20px",
        }}
      >
        <img
          alt="logo"
          style={{
            objectFit: "contain",
          }}
          src={require("../../assets/images/bf_logo.png")}
          height={"25px"}
          width={"25px"}
        />
      </div>
    </div>
  );
};
