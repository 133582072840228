export default {
  landing_page: "",
  challenges: "challenges",
  exercises: "exercises",
  home: "home",
  user: "users",
  videos: "videos",
  workouts: "workouts",
  user_detail: "users/detail/",
};
